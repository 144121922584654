import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-3f13bf02")
const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-action" }
const _hoisted_3 = { class: "image-holder" }
const _hoisted_4 = { class: "card-text" }
const _hoisted_5 = { class: "card-title" }
const _hoisted_6 = { class: "card-subtitle" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_ImageWithLoader = _resolveComponent("ImageWithLoader")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: "remove-button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-remove', _ctx.asset)))
      }, [
        _createVNode(_component_svg_icon, {
          class: "icon icon--medium",
          local: "",
          src: require('./images/remove-icon.svg')
        }, null, 8, ["src"])
      ])
    ]),
    _createVNode(_component_router_link, {
      to: _ctx.getFullAssetRouteAsString(_ctx.asset),
      class: "card-link"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_ImageWithLoader, {
            ref: "image",
            key: _ctx.asset?.src,
            src: _ctx.asset?.src,
            d3model: _ctx.asset?.assetType === _ctx.isModels,
            video: _ctx.asset?.assetType === _ctx.isVideo,
            alt: _ctx.asset?.src
          }, null, 8, ["src", "d3model", "video", "alt"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.asset.title), 1),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.getAssetsTypesInString([_ctx.asset.assetType])), 1)
        ])
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}